@import "src/common/styles/variables";

.displayPosition {
	position: fixed;
	z-index: 9999;
	bottom: 20px;
	left: 10px;
	color: #000;
	width: 400px;
	.toast-header {
		border: unset;
		margin-left: 240px;
	}
	.toast {
		max-width: 400px;
	}
	.toast.show {
		border-radius: 20px;
		display: block;
		opacity: 1;
	}

	.noti-block {
		display: flex;
	}

	.noti-content {
		margin-left: 14px;
	}

	.noti-description {
		font-size: 16px;
		color: $secondaryGray;
	}
	h3 {
		color: $primaryBlue;
		font-weight: 700;
	}
	.fadeInLeftBig {
		-webkit-animation-name: fadeInLeftBig;
		animation-name: fadeInLeftBig;
		-webkit-animation-duration: 1s;
		animation-duration: 1s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}
	@-webkit-keyframes fadeInLeftBig {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(-2000px, 0, 0);
			transform: translate3d(-2000px, 0, 0);
		}
		100% {
			opacity: 1;
			-webkit-transform: none;
			transform: none;
		}
	}
	@keyframes fadeInLeftBig {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(-2000px, 0, 0);
			transform: translate3d(-2000px, 0, 0);
		}
		100% {
			opacity: 1;
			-webkit-transform: none;
			transform: none;
		}
	}
	.buttonClose {
		margin-left: 110px;
	}

	.timeText {
		color: $textGray;
		font-size: 12px;
		margin-top: -15px;
	}

	@media screen and (max-width: 425px) {
		display: none;
	}
}
