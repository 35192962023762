@import "src/common/styles/variables";

.reviews-wrap {
	.reviews-wrap-row {
		padding: 20px 0;
	}
	.youtube-img {
		cursor: pointer;
		object-fit: contain;
		border-radius: 15px;
		width: 100%;
		height: auto;
	}

	.review-heading {
		display: flex;
		color: $white;
		margin-bottom: 20px;

		.rating-number {
			background: $primaryBlue;
			width: 110px;
			font-size: 45px;
			border-top-left-radius: 15px;
			display: flex;
			font-weight: bold;
			font-family: "Condensed", sans-serif;
			font-style: italic;
			justify-content: center;
			align-items: center;
		}

		.rating-text {
			padding: 10px;
			display: flex;
			background: $reviewHeadingBg;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			line-height: 25px;
			p {
				font-size: 18px;
				color: $white !important;
				margin-bottom: 0;
			}
		}
	}
}

.video-modal {
	max-width: 640px !important;
	height: 80%;
	display: flex;
	align-items: center;
	.youtube-video-wrapper {
		position: relative;
		overflow: hidden;
		width: 100%;
		padding-top: 56.25%;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
		}
	}
}
