.review-text {
	p {
		margin-bottom: 0;
	}
	.feedback-date {
		display: flex;
		justify-content: flex-end;
		margin-right: 20px;
		margin-bottom: 0.5rem;
		font-size: 12px;
	}
}
