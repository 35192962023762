@import "src/common/styles/variables";

.give-feedback-wrap {
	.container {
		background: #f6f6f6;
		border-radius: 15px;
		margin: 20px auto;
	}
	.main-heading {
		margin-top: 25px;
		margin-bottom: -20px;
		font-size: 18px;
	}
	.feedback-heading {
		margin-top: 3%;
		text-align: center;
		color: $primaryGray;
		font-family: "Open Sans Condensed", sans-serif;
		font-size: 2rem;
		@media screen and (max-width: 580px) {
			font-size: 1.5rem;
		}
	}

	.alert-wrap {
		margin-top: 47px;

		.alert {
			text-align: left;
			z-index: 9;
		}
	}

	.feedback-input-wrap {
		margin-top: 47px;

		&.is-alert-active {
			margin-top: 0;
		}

		.stars-wrap {
			display: flex;
			align-items: center;

			.ratings-text {
				color: $white;
				margin-left: 25px;
				font-size: 13px;
				margin-top: 10px;
			}
		}

		.stars span {
			@media screen and (max-width: 580px) {
				font-size: 30px !important;
			}
		}
		.feedback-given {
			span {
				font-size: 1.5rem;
				color: $white;
				font-family: "Montserrat";
			}

			.feedback-textarea {
				position: relative;
			}
			.feedback-details-wrap {
				position: absolute;
				top: 60px;
				left: 20px;

				.stars {
					margin-right: 160px;
					@media screen and (max-width: 768px) {
						margin-right: 25px;
					}
				}
				.feedback-content {
					font-size: 18px;
					color: rgba(112, 112, 112, 1);
				}
				.user-name {
					font-family: "Montserrat";
					font-style: normal;
					font-weight: bold;
					font-size: 26px;
					color: rgba(112, 112, 112, 1);
				}
			}
			.share-review-text {
				font-family: "Montserrat";
				font-weight: bold;
				font-size: 22px;
				color: $white;
				margin-top: 25px;
			}
			.share-icon {
				width: 39px !important;
				height: 39px;
				margin-right: 20px;
			}
		}
		.give-feedback {
			background-color: $primaryBlue;
			width: 100%;
			border-radius: 10px;
			padding: 20px;
			margin-bottom: 1rem;

			.label {
				color: $primaryGray;
				font-family: Roboto;
				font-size: 1.2rem;
				display: inline-block;
				font-weight: 700;
				margin-bottom: 7px;
			}
			.value {
				font-size: 1.5rem;
				color: $white;
				font-family: Roboto;
				display: flex;
				font-weight: 700;
				margin-top: -10px;
			}

			.feedback-textarea {
				margin-top: 12px;
				color: $primaryGray;
				font-size: 1.2rem;
				span {
					font-family: Roboto;
					font-weight: 700;
					display: flex;
					margin-bottom: 6px;
				}

				textarea {
					height: 162px !important;
				}
			}
			button {
				width: 30% !important;
				margin-top: 20px;
				font-family: Roboto;
			}
		}
		p {
			color: #7f807a;
			font-family: "Open Sans Condensed", sans-serif;
		}
		.btn-sr-yellow.disabled {
			opacity: 0.65;
		}
	}
}
