@import "src/common/styles/variables";

.calculate {
	.component-wrapper {
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;

		.inner-component-wrapper {
			max-width: 1000px;
			width: 100%;
			height: 100%;
		}

		@media screen and (max-width: 480px) {
			.listItem {
				ul {
					margin-left: 0;
				}
			}
		}
		@media screen and (max-width: 1024px) {
			flex-direction: column;
		}
	}

	.stepper {
		box-shadow: unset;
		@media screen and (max-width: 768px) {
			margin-bottom: 0 !important;
			padding-top: 4px;
			.stepwizard {
				margin: 0 !important;
			}
		}
	}

	.stepper__header {
		box-shadow: unset;
	}

	.btn-offerte {
		margin: 20px;
		font-weight: 700;
		font-size: 16px;
		border-radius: 16px;
		background: $bgLightGray;
		color: $primaryDarkerBlue;
		border: 1px solid $primaryDarkerBlue;
		box-shadow: 1px 2px $primaryDarkerBlue;
		@media screen and (max-width: 768px) {
			margin: 15px 0 90px 0;
		}
	}

	// .btn-primary:hover {
	// 	background: $bgLightGray;
	// 	color: $primaryDarkerBlue;
	// 	border: 1px solid $primaryDarkerBlue;
	// 	box-shadow: 1px 2px $primaryDarkerBlue;
	// }
	.detailsCard {
		margin-top: 20px !important;
	}
	.clientDetail {
		padding-right: 0px !important;
	}
	.courierDetail {
		padding-left: 0px !important;
	}
	@media screen and (max-width: 768px) {
		.clientDetail {
			padding: 0 15px !important;
		}
		.courierDetail {
			padding: 0 15px !important;
		}
	}
}
.listItem {
	margin: 15px;
	ul {
		list-style: none;
		padding: 0;
		font-size: 12px;
	}
	li:before {
		content: "\f04d";
		font-family: FontAwesome;
		color: red;
		margin-right: 10px;
	}
}
