@import "stepper";
//utilites
@import "variables";

#tawkchat-container {
	@media screen and (max-width: 767px) {
		transform: translateY(-55px) !important;
	}
}

.card {
	background: $white;
	border-radius: 15px !important;
	box-shadow: 0 0 8px 0 rgba(70, 70, 70, 0.5);
}

.form-control {
	border-radius: 5px !important;
	border: 1px solid #000000 !important;
	color: unset;

	&.required {
		background: url("/required.gif") top right no-repeat $white;
	}
}

.modal-content {
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 15px !important;
	box-shadow: 0 0 8px 0 rgba(70, 70, 70, 0.5);
}

.modal-open .container-fluid,
.modal-open .container-lg {
	-webkit-filter: blur(5px) grayscale(90%);
}

.is-invalid {
	border-color: #dc3545 !important;
}

input.is-invalid.form-check-input:before {
	position: absolute;
	content: "";
	width: 13px;
	height: 13px;
	border: 1px solid red;
	border-radius: 2px;
}

.btn-sr-yellow {
	color: $bgLightGray;
	font-size: 16px !important;
	width: 100% !important;
	outline: 0 !important;
	padding: 9px 0 !important;
	border: 0 !important;
	background-color: $btnPrimaryYellow !important;
	border-radius: 26px !important;
	border-bottom: 3px solid $btnSecondaryYellow !important;
	border-top: 0 solid $darkyello !important;
	font-weight: 700 !important;
	text-decoration: none !important;
	// -webkit-transition: all 0.1s ease-in-out;
	// -moz-transition: all 0.1s ease-in-out;
	// -ms-transition: all 0.1s ease-in-out;
	// -o-transition: all 0.1s ease-in-out;
	// transition: all 0.1s ease-in-out !important;

	&:focus {
		box-shadow: none !important;
	}

	&:hover {
		background-color: $btnSecondaryYellow !important;
		border-bottom: 0 solid $btnPrimaryYellow !important;
		border-top: 3px solid $btnSecondaryYellow !important;
		// -webkit-transition: all 0.1s ease-in-out;
		// -moz-transition: all 0.1s ease-in-out;
		// -ms-transition: all 0.1s ease-in-out;
		// -o-transition: all 0.1s ease-in-out;
		// transition: all 0.1s ease-in-out !important;
	}
}

.bottom-shipment-span {
	position: relative;
	margin-right: 90px;
	bottom: 15px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
	padding-right: calc(1.5em + 1.75rem);
	background-repeat: no-repeat;
	background-position: right calc(1.2em + 0.1875rem) center !important;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.selectCourierError {
	left: 20%;

	@media screen and (max-width: 580px) {
		left: 0% !important;
	}
}
