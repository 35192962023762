.stepper {
	margin: 15px 0 0 0;
	border: unset;
	box-shadow: 0 0 2px 2px #ccc6;
}

.stepper--horizontal .stepper__header {
	flex-direction: row;
	height: 139px;
}

.stepper--horizontal .stepper__head {
	height: 72px;
	width: 350px;
}

.stepper__head--active .stepper__head__index {
	background: #71bce2;
}

.stepper__head__title {
	align-items: unset;
	font-weight: 700;
}

.stepper__head__index {
	height: 30px;
	width: 37px;
}

.stepper--horizontal .stepper__connector {
	min-width: unset;
	margin: unset;
}

.stepper__head--disabled .stepper__head__index {
	width: 80px;
}
