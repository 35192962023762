@import "src/common/styles/variables";

.main-wrapper {
	margin: 60px auto;
	padding: 17px;
	max-width: 700px;
	width: 100%;
	margin-bottom: 10px;
	background-color: $bgLightGray;
	font-family: "Condensed", sans-serif;
	font-size: 13px;
	line-height: 16px;

	h3 {
		color: $primaryBlue;
		font: bold italic 26px arial;
		text-transform: uppercase;
	}
	p {
		color: $gray;
	}
	.unsubscribe-btn {
		background-color: $primaryBlue;
		width: 150px;
		text-transform: uppercase;
		border: none;
		font-weight: bold;
		height: 38px;
		padding: 7px 0;
		font: bold italic 13px arial;
		@media screen and (max-width: 767px) {
			width: 100%;
			margin-top: 20px;
		}
	}
}
