@import "src/common/styles/variables";

.input-wrap {
	.input-title {
		margin-bottom: 5px;
		font-weight: 600;
		color: $modalGray !important;
	}
	.disableInput {
		cursor: not-allowed;
	}
	.dimention-icon {
		background: #e4e6e7;
		width: 40px;
		z-index: 2;
		display: flex;
		color: $modalGray;
		font-size: 13px;
		height: 45px;
		margin-top: 6px;
		align-items: center;
		justify-content: center;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}
	.dimention-symbol {
		margin-left: 5px;
		color: $modalGray;
		font-weight: 500;
		font-size: 13px;
	}
	.bottom-shipment-details {
		margin-top: 25px;

		@media screen and (max-width: 768px) {
			h3 {
				font-size: 20px;
			}
		}
	}
	input {
		width: 80px;
		height: 45px;
		margin-left: -2px;
		margin-top: 6px;
		border: 1px solid #e4e6e7;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		padding: 0 4px 0 15px;
		color: #000000 !important;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;

		&:focus {
			outline: none;
		}

		@media screen and (max-width: 400px) {
			width: 60px;
		}
	}

	&.error {
		.dimention-icon,
		input {
			border: 1px solid #dc3545;
		}
	}
}
