@import "src/common/styles/variables";

.vehicle-modal {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99999;
	height: inherit;
	margin: 0 auto !important;
	@media screen and (min-width: 576px) {
		max-width: 600px !important;
	}
	.myRow {
		margin: 20px;
		display: flex;
		justify-content: center;

		@media screen and (max-width: 500px) {
			margin-left: 0;
			margin-right: 0;
			flex-wrap: inherit;
		}
	}

	.modal-content {
		max-width: 600px;
		width: 100%;
		overflow: hidden;
		@media screen and (max-width: 768px) {
			max-width: unset !important;
			width: 100%;
		}
	}

	.title h2 {
		font-weight: bold;
	}

	.modal-close-icon {
		position: absolute;
		top: 43px;
		right: 0;
		cursor: pointer;
		font-size: 20px;
		font-weight: bold;
	}

	.peter-noLicense {
		margin-left: -12px;
		float: left;
		margin-top: -335px;
		height: 335px;
		border-radius: 15px;
	}
	img {
		vertical-align: middle;
		border-style: none;
		object-fit: cover;
	}
	.vehicleNumberStyle {
		background-position: left !important;
		flex: unset !important;
		width: 270px;
		background-color: $btnPrimaryYellow !important; //#fec62e
		color: #000;
		border: none !important;
		border-radius: 8px 0 0 8px !important;
		padding: 10px 0 10px 20px !important;
		text-align: center !important;
		max-width: 35rem !important;
		height: unset !important;
		background-image: url(https://serviceright-autos.nl/wp-content/themes/serviceright-theme/assets/img/EU-section-with-NL_spoochy.svg);
		background-repeat: no-repeat !important;
		background-size: contain !important;
		text-transform: uppercase;
		font-weight: bold !important;
		//color: #7b7b7b !important;

		@media screen and (max-width: 500px) {
			width: auto;
		}

		@media screen and (max-width: 330px) {
			width: 220px;
		}
	}
	.form-control {
		display: block;
		flex: 1;
	}
	.form-control:focus {
		color: unset;
		background-color: unset;
		border-color: unset;
		outline: unset;
		box-shadow: unset;
	}
	.submitVehicleNumber {
		cursor: not-allowed !important;
		padding: 9.5px;
		opacity: 0.3;
		transition: opacity 200ms;
		border-radius: 0 8px 8px 0 !important;
		border-top: 0;
		height: 45px;
		border-bottom: 3px;
		border-right: 1px;
		border: solid $bgLightGray;
		margin-top: 0;
		width: 90px;
		outline: 0;
		text-align: center;

		&:hover {
			.caret-right {
				transform: translateY(2px);
			}
		}

		&.isValid {
			opacity: 1;
			cursor: pointer !important;
			border-left: 1px solid;
		}

		.spinner-border {
			color: #53554c;
			font-size: 7px;
			width: 1.7rem;
			height: 1.7rem;
		}

		@media screen and (max-width: 500px) {
			width: auto;
		}
	}
	.caret-right {
		font-size: 25px;
	}
	.btnCat {
		border-radius: 100px;
		color: $bgLightGray;
		border: 0;
		background-color: $btnPrimaryYellow;
		border-bottom: 3px solid $btnSecondaryYellow;
		border-top: 0 solid $btnSecondaryYellow;
	}
	.btnCat:hover {
		background-color: $btnSecondaryYellow;
	}
	.itemDetail {
		text-align: center;
		h5 {
			margin: 15px;
		}
	}
	.imageClass {
		border-radius: 25px;
		width: 100%;
	}
	.questionStyle {
		margin: 10px;
		font-size: x-large;
		font-weight: bold;
		color: $primaryBlue;
	}
	.btn {
		border-radius: 30px !important;
		width: 100px;
		height: 45px;
		font-weight: bold !important;
	}

	.btnNo {
		color: $bgLightGray !important;
		background-color: $btnLightGray !important;
		border-bottom: 3px solid $secondaryGray !important;
		border-top: 0 solid transparent !important;

		&:hover {
			background-color: $secondaryGray !important;
			border-bottom: 0 solid $btnLightGray !important;
			border-top: 3px solid $secondaryGray !important;
		}
	}

	.btnYes {
		color: $bgLightGray !important;
		background-color: $btnPrimaryYellow !important;
		border-bottom: 3px solid $btnSecondaryYellow;
		border-top: 0 solid transparent;

		&:hover {
			background-color: $btnSecondaryYellow !important;
			border-bottom: 0 solid $btnPrimaryYellow;
			border-top: 3px solid $btnSecondaryYellow;
		}
	}

	.noLicenseText {
		text-align: end;
		margin: 0px 20px;
		cursor: pointer;

		hr {
			background: #000;
		}

		.btnYes {
			color: $bgLightGray !important;
			border-top: 0 solid $darkyello;
			outline: 0 !important;
			background-color: $btnPrimaryYellow !important;
			border-bottom: 3px solid $btnSecondaryYellow;
			border-top: 0 solid $darkyello;

			&:hover {
				border-bottom: 0 solid $btnPrimaryYellow;
				border-top: 3px solid $btnSecondaryYellow;
				-webkit-transition: all 0.1s ease-in-out;
				-moz-transition: all 0.1s ease-in-out;
				-ms-transition: all 0.1s ease-in-out;
				-o-transition: all 0.1s ease-in-out;
				transition: all 0.1s ease-in-out;
				color: #fff;

				background-color: $btnSecondaryYellow;
				border-bottom: 0 solid $btnPrimaryYellow;
				border-top: 3px solid $btnSecondaryYellow;
			}
		}

		.noLicenseText {
			text-align: end;
			margin: 0px 20px;
			cursor: pointer;
		}

		.selectButtonNo {
			background-color: #007bff !important;
			border-color: #007bff !important;

			&:hover {
				background-color: #0069d9 !important;
				border-bottom: 0 solid #0062cc !important;
				border-top: 3px solid #0062cc !important;
				-webkit-transition: all 0.1s ease-in-out;
				-moz-transition: all 0.1s ease-in-out;
				-ms-transition: all 0.1s ease-in-out;
				-o-transition: all 0.1s ease-in-out;
				transition: all 0.1s ease-in-out;
				color: #fff;
			}
		}

		.selectButton {
			color: $bgLightGray;
			width: 100%;
			outline: 0 !important;
			padding: 9px 0;
			border: 0;
			background-color: $btnPrimaryYellow;
			border-bottom: 3px solid $btnSecondaryYellow;
			border-top: 0 solid $darkyello;
		}
		.selectButton:hover {
			background-color: #e6b70b;
			border-bottom: 0 solid #ffc800;
			border-top: 3px solid #e6b70b;
			-webkit-transition: all 0.1s ease-in-out;
			-moz-transition: all 0.1s ease-in-out;
			-ms-transition: all 0.1s ease-in-out;
			-o-transition: all 0.1s ease-in-out;
			transition: all 0.1s ease-in-out;
			color: #fff;
		}
		.btnDisabled {
			width: 100%;
			cursor: not-allowed;
		}
		.selectBox {
			margin: 25px;
			@media screen and (max-width: 768px) {
				margin: 15px 0;
			}
		}
		.selectBox:focus {
			background-color: $white;
		}
		.orText {
			text-align: center;
		}

		.text-center {
			text-align: center !important;
		}
	}

	.select-car .btn-sr-yellow {
		@media screen and (max-width: 500px) {
			width: 80% !important;
			margin: 0 auto;
			display: flex;
			justify-content: center;
		}
	}

	.selectBox {
		margin-left: auto;
		margin-right: auto;
		max-width: 327px;
		margin-bottom: 1.3rem;
		color: $input-gray;
		-webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05),
			inset 0 1px 0 rgba(255, 255, 255, 0.8);
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05),
			inset 0 1px 0 rgba(255, 255, 255, 0.8);
		background-color: #f9f9f9;
		background-image: -moz-linear-gradient(top, #fefefe, #f2f2f2);
		background-image: -webkit-gradient(
			linear,
			0 0,
			0 100%,
			from(#fefefe),
			to(#f2f2f2)
		);
		background-image: -webkit-linear-gradient(top, #fefefe, #f2f2f2);
		background-image: -o-linear-gradient(top, #fefefe, #f2f2f2);
		background-image: linear-gradient(to bottom, #fefefe, #f2f2f2);
		background-repeat: repeat-x;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffefefe', endColorstr='#fff2f2f2', GradientType=0);
		border-color: #b8b8b8 !important;
		line-height: 18px;
		font-size: 13px;
		text-align: center;

		&:disabled {
			opacity: 0.5;
		}

		@media screen and (max-width: 500px) {
			width: 80%;
		}
	}

	h3.title {
		font-size: 16px;
		line-height: 24px;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
			"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
			"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	}

	.title {
		font-family: sans-serif;
		font-weight: bold;
		color: $primaryGray;

		@media screen and (max-width: 768px) {
			font-size: 24px;
			line-height: 28px;
		}
	}
	.go-back-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		color: $white;

		&:hover {
			color: $white;
			text-decoration: none;
		}
	}

	@media screen and (max-width: 400px) {
		.modal-body {
			overflow-y: scroll;
			max-height: 95vh;
			height: auto;
		}
	}
}
