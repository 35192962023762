$primaryBlue: #2c9dd3;
$secondaryBlue: #257ca7;
$primaryDarkerBlue: #2a7ba6;
$darkerBlue: #2987b5;
$lightBlue: #cde3ef;

$primaryGray: #53554c;
$secondaryGray: #868686;
$btnLightGray: #b9b9b9;
$whiteGray: #f8fbfc;
$lineGray: #dee2e6;

$btnPrimaryYellow: #ffc800;
$btnSecondaryYellow: #e6b70b;
$darkyello: #fcac00;

$btnPrimaryOrange: #db7b2b;
$btnSecondaryOrange: #bf642a;

$borderGray: #707070;
$lightBorderGray: #cccccc6e;
$textBlack: #1c1c1c;
$textGray: #626262;
$textLightGray: #b8b8b8;
$textDarkGray: #4b4b4b;
$textRed: #de0000;
$txtDanger: #f44406;
$lightRed: #f8d7da;
$darkRed: #721c24;

$input-gray: #303030;

$bgLightGray: #f6f6f6;
$reviewHeadingBg: #484848;

$checkboxBgGray: #d3d3d3;
$rowLightBlue: #f3fbff;
$white: #ffffff;
$gray: #808080;
$modalGray: #7f7f7f;
