.halfStar {
	&:before {
		content: attr(data-forhalf);
		position: absolute;
		overflow: hidden;
		display: block;
		z-index: 1;
		top: 0;
		left: 0;
		width: 51%;
		height: 22px;
		color: rgb(250, 202, 48);
	}
}
