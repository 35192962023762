@import "../../../common/styles/variables";

.stepperButtonContainer {
	padding: 10px;
}

.stepperButtonGrid {
	display: flex;
	justify-content: center;
	align-items: center;
}

.dimentions-error {
	margin-left: 16.666667%;
	margin-top: 20px;
}

.input-courier-wrap {
	position: relative;
	margin: 13px 0;

	input {
		padding-right: 25px;

		&:focus {
			box-shadow: none;
		}
	}

	span {
		cursor: pointer;
		position: absolute;
		right: 10px;
		top: 8px;
	}
}

.action-btns-wrap {
	margin-top: 20px;

	.back-btn-col {
		@media screen and (min-width: 768px) and (max-width: 1024px) {
			max-width: 110px;
			width: 100%;
		}
	}

	.back-btn {
		width: 100%;
		@media screen and (max-width: 768px) {
			margin-bottom: 10px;
		}
	}
}

.shipment-details-wrap {
	.selectShipmentTypeText {
		color: $modalGray;
		font-weight: 600;
	}

	.headingStyle {
		text-align: left;
	}

	.shipment-type-items-wrap {
		display: flex;
		flex-wrap: wrap;
		margin: 10px 0;
	}
	.shipment-type-item {
		width: 85px;
		height: 85px;
		margin: 2px;
		font-weight: 600;
		padding: 5px;
		border-radius: 4px;
		font-size: 13px;
		border: 1px solid transparent;
		background: #e4e6e7;
		color: $modalGray;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		cursor: pointer;

		&.selected-item {
			background: white;
			border: 1px solid #ef8a08;
			color: #ef8a08;
		}
	}
}

.new-design-modal.change-location-modal {
	max-width: 600px;
	//margin-top: 90px !important;

	.bgImg {
		right: 5px;
		left: unset;
		margin-left: 0;
	}
}
