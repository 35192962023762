@import "src/common/styles/variables";

.embed-submit-field input {
	width: 98%;
	padding: 0 30px 0 15px;
	margin-left: 1%;
}
.embed-submit-field button {
	border: none;
	/*height: 10px;*/
	/*width: 10px*/
}

.close-icon {
	border: none;
	background-color: transparent;
	position: absolute;
	top: 7px;
	right: 12px;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	opacity: 0.5;
	padding: 0;

	&:hover {
		opacity: 0.75;
	}
}

.info_text {
	font-family: sans-serif, Montserrat;
}

.phoneNumber {
	cursor: pointer;
	font-weight: bold;
	color: $primaryBlue;
	font-size: 25px;
	font-family: sans-serif, Montserrat;

	&:hover {
		color: $primaryBlue;
	}
}

.timeText {
	font-size: 16px;
	color: $secondaryGray;
	font-family: sans-serif, Montserrat;
}

.phoneContainer {
	flex: 1;
	justify-content: center;
	align-items: center;
	display: flex;

	@media screen and (max-width: 992px) {
		flex: unset;
		margin: 0 auto;
	}
}

.footerTextContainer {
	flex: 1;

	@media screen and (max-width: 992px) {
		flex: unset;
		margin: 0 auto;
	}

	@media screen and (max-width: 767px) {
		max-width: 220px;
	}
}

.modal-dialog {
	@media (max-width: 768px) {
		padding: 5%;
		max-width: 100% !important;
	}
	@media (max-width: 320px) {
		padding: 0;
	}
}
