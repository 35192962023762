@import "src/common/styles/variables";

.error-page-wrap {
	color: $white;
	margin: 50px auto !important;

	.left-side {
		background: $primaryBlue;
		min-height: 17.5rem;
		max-height: 100%;
		padding: 1rem;

		a {
			color: $white;
		}

		.open-popup-btn {
			color: $bgLightGray;
			width: 100%;
			outline: 0 !important;
			padding: 9px 0;
			border: 0;
			background-color: $btnSecondaryYellow;
			border-radius: 26px;
			border-bottom: 3px solid $btnSecondaryYellow;
			border-top: 0 solid $darkyello;
		}
	}
	.right-side {
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 17.5rem;
		&.vehicles {
			background-image: url(https://vandaag-autos.nl/wp-content/themes/serviceright-theme/assets/img/404.jpg);
		}
		&.couriers {
			background-image: url(https://vandaag-koeriers.nl/wp-content/themes/serviceright-courier/assets/img/404.jpg);
		}
	}
}
