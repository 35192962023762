@import "src/common/styles/variables";

.stepperContainer {
	padding: 10px;
}

.single-vehicle-wrap {
	@media screen and (max-width: 768px) {
		margin-bottom: 20px;
	}
}

.card {
	background-color: $white;
	// min-height: 230px;

	&.cardSelected {
		background-color: $primaryBlue;
		color: $white;
	}

	.cardTitle {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		line-height: 16px;
		height: 34px;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		margin-bottom: 0;
	}
	.cardImg {
		border-top-left-radius: 14px;
		border-top-right-radius: 14px;
		object-fit: cover;
		height: 100px;
	}
}

.stepperButtonGrid {
	display: flex;
	justify-content: center;
	align-items: center;
}

.action-btns-wrap {
	.back-btn {
		padding: 9px 0.75rem;
		border-radius: 26px;
		font-weight: bold;
	}
}

.vehicle-error-wrap {
	a,
	span {
		color: $primaryBlue;
		cursor: pointer;

		&:hover {
			color: $secondaryBlue;
			text-decoration: underline;
		}
	}
}

.alert-danger {
	border-radius: 12px !important;
	width: 96%;
	position: relative !important;
	z-index: 67;
	left: 5px;
	margin-right: 0;
	text-align: right;

	ul {
		margin-top: 20px;
	}
}
