@import "src/common/styles/variables";

.modal {
	.modal-dialog.feedback-modal {
		margin-top: 150px;
		margin-top: 150px;
		.modal-content {
			.modal-header.modalHeader {
				padding: 0 15px !important;
				align-items: center !important;
				background: $reviewHeadingBg;
				border-radius: 15px 15px 0 0;
				position: relative;
				.review {
					.review-wrap {
						background: #449ad2;
						border-top-left-radius: 15px;
						display: flex;
						align-items: center;
						.reviewstate {
							@media screen and (max-width: 375px) {
								font-size: 2em;
							}
						}
						p {
							font-size: 2.5em;
							color: #fff;
							margin-bottom: 0;
						}
					}
					.review-create {
						padding: 10px;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;
						line-height: 25px;
						p {
							font-size: 18px;
							color: #fff !important;
							margin-bottom: 0;
						}
					}
				}
				button {
					position: absolute;
					top: 0;
					right: 20px;
					span {
						font-size: 48px;
						color: #fff;
					}
				}
			}
			.scrollData {
				max-height: 350px;
				overflow: auto;
			}
		}
	}
}
