@import "src/common/styles/variables";

.datepicker-container {
	width: 100%;

	div {
		//width: 100%;
	}
}

.change-location-modal {
	display: flex;
	align-items: center;
	margin-bottom: 0 !important;
	height: 90vh;
	margin-top: 5vh !important;
	color: $primaryBlue;

	.divider-row {
		margin: 15px 0;
	}

	.modal-close-icon {
		position: absolute;
		top: 10px;
		right: 20px;
		cursor: pointer;
		font-size: 20px;
		color: black;
		font-weight: bold;
	}

	.shipment-details-wrap {
		@media screen and (max-width: 426px) {
			.recommended-wrap {
				top: 55px !important;
				left: 100px !important;

				span {
					left: 30px;
				}
				.recommended-arrow {
					transform: rotate(280deg) !important;
				}
			}
			.addMarginTop {
				margin-top: 75px;
			}
		}

		.recommended-wrap {
			position: absolute;
			top: 80px;
			width: 70px;
			left: -50px;

			span {
				position: relative;
				top: 17px;
				right: 15px;
				font-family: "bradley_handregular", serif;
			}
			.recommended-arrow {
				transform: rotate(208deg);
				width: 70px;
				height: 70px;
			}
		}
		h2 {
			margin-left: 5px;
			color: $primaryGray;
			font-weight: bold;
		}

		input,
		select {
			color: $primaryBlue;
		}

		.input-wrap {
			span.input-title {
				color: $primaryGray;
				font-weight: bold;
			}
		}
	}

	.modal-content {
		max-height: 100vh;
		.modal-header {
			padding: 0;
			border-bottom: none;
			position: relative;
			button {
				position: absolute;
				top: 17px;
				right: 20px;
				z-index: 9;
				cursor: pointer;
				outline: none;
				span {
					font-size: 50px;
				}
			}
		}
	}
	.modal-dialog {
		max-width: 689px;
	}
	.form-group {
		position: relative;
		.Demo__autocomplete-container {
			position: absolute;
			z-index: 9;
			width: 100%;
			border-bottom: honeydew;
			border-left: honeydew;
			border-right: honeydew;
			border-top: 1px solid #e6e6e6;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
			border-radius: 0 0 2px 2px;
			background: $whiteGray;
			overflow: scroll;
			height: 240px;
			padding-bottom: 20px;
		}
		.Demo__suggestion-item {
			padding: 10px;
			cursor: pointer;
			color: $textBlack;
			background-color: $white;
			border: 1px solid $lightBorderGray;
			border-top: 0;
			z-index: 19;
			&:hover {
				background-color: $lightBlue;
			}
		}
	}

	.title {
		margin: 0 auto;
		text-align: center;
		font-weight: bold;
		font-size: 35px;
		padding: 2rem;
		color: $primaryGray;
		font-family: sans-serif;

		h2 {
			font-weight: bold;
		}
	}

	.slight-italic {
		transform: skew(-5deg);
	}

	input {
		height: 40px;
		width: 100%;
		//border: 1px solid #ced4da;
		border-radius: 0.25rem;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		padding: 0.375rem 0.75rem;
	}
	.modal-dialog {
		max-width: 689px;
		margin: 0 auto;
	}

	.btn-go-wrap {
		width: 100%;
		padding: 0 20px;
		.btnDisabled {
			font-weight: 700;
			font-size: 16px;
			border-radius: 100px;
			color: $bgLightGray;
			//box-shadow: 1px 2px $darkyello;
			outline: 0 !important;
			padding: 9px 0;
			border: 0;
			background-color: #7b7b7b;
			border-bottom: 3px solid #7b7b7b;
			border-top: 0 solid #7b7b7b;
			opacity: 0.5;
			cursor: not-allowed;
		}
	}

	.slight-italic {
		transform: skew(-5deg);
	}

	.subtitle {
		//padding-top: 23px;
		color: $primaryGray;
		font-family: sans-serif;
		font-weight: bold;
		font-size: 24px;
	}

	.text-center {
		text-align: center !important;
	}

	.divider-row p {
		color: black;
	}

	.call {
		color: $primaryBlue;
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}

	.bgImg {
		width: 210px;
		position: absolute;
		bottom: 0;
		left: 0;
		margin-left: -56px;
	}
	.modal-content {
		background: $bgLightGray;
		overflow: hidden;
	}
	.modal-body {
		padding: 2rem;
		max-height: 100vh;
		overflow: scroll;
	}

	@media screen and (max-width: 400px) {
		.modal-body {
			overflow-y: scroll;
			max-height: 95vh;
			height: auto;
		}
	}
}

@media screen and (max-width: 767px) {
	.modal {
		.modal-dialog.change-location-modal {
			text-align: center;
			.modal-content {
				.title {
					padding: 1rem;
				}

				.modal-body {
					form {
						.btn-go-wrap {
							.appointment {
								.subtitle {
									font-size: 17px;
								}
								.call {
									font-size: 24px;
									span {
										font-size: 18px;
									}
								}
							}
						}
					}

					.shipment-details-wrap .shipment-type-items-wrap {
						justify-content: center;
					}
				}
				.bgImg {
					display: none;
				}
			}
		}
	}
}
