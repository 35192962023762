@import "src/common/styles/variables";

.main-wrapper {
	text-align: center;
	font-family: "Condensed", sans-serif;

	h2 {
		color: $primaryBlue;
		font: bold italic 26px arial;
		text-transform: uppercase;
	}
	b,
	p {
		color: $gray;
	}
	.left-side {
		text-align: left;
	}
	.right-side {
		border: 5px solid #adc941;
		border-style: dashed;
		padding: 20px 0;
	}
	// .unsubscribe-btn {
	// 	background-color: $primaryBlue;
	// 	width: 150px;
	// 	text-transform: uppercase;
	// 	border: none;
	// 	font-weight: bold;
	// 	height: 38px;
	// 	padding: 7px 0;
	// 	font: bold italic 13px arial;
	// 	@media screen and (max-width: 767px) {
	// 		width: 100%;
	// 		margin-top: 20px;
	// 	}
	// }
}
