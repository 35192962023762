$anchor-color: #27ae60;

.coming-soon {
	height: 100vh;
	background: #fafafa;

	.content {
		max-width: 600px;
		height: 100%;
		margin: 0 auto;

		img {
			width: 100%;
		}

		h1 {
			font-family: Helvetica, Arial, sans-serif;
			font-weight: 400;
			font-style: normal;
			font-size: 30px;
			color: #444;
			line-height: 1em;
		}

		p {
			font-family: Helvetica, Arial, sans-serif;
			font-weight: 400;
			font-style: normal;
			font-size: 16px;
			line-height: 1.5em;
			color: #666;
			max-width: 500px;
			margin: 0 auto;
		}

		a {
			text-decoration: none;
			color: $anchor-color;

			&:hover,
			&:visited,
			&:focus {
				text-decoration: underline;
			}
		}

		form {
			width: 100%;

			.form-group {
				width: 100%;

				input {
					height: 46px;
					padding: 10px 16px;
					font-size: 18px;
					line-height: 1.3333333;
					border-radius: 6px;
					border-color: transparent;

					&#coming-soon-email {
						background-color: #f5f5f5;
						outline: none;
						box-shadow: none;

						&:focus {
							outline: none;
							box-shadow: none;
						}
					}
				}

				input[type="submit"] {
					@media screen and (max-width: 575px) {
						width: 100%;
					}
				}
			}
		}
	}
}
