body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.input-style input:disabled,
.input-style select:disabled {
	background: #ffffff;
}

.row-gap {
	margin-bottom: 10px;
}

.collapse-button {
	border: 0.5px solid #f5f5f5;
	padding: 10px;
	border-radius: 5px;
	background: #ffffff;
	transition: all linear 0.4s;
	min-width: 25px;
	min-height: 10px;
	margin: 0 auto 30px;
	display: flex;
	font-size: 18px;
	font-weight: 700;
	color: #808080;
}

.collapse-button:hover {
	box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
	-webkit-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
}

.serviceSection {
	padding: 20px 0;
	position: relative;
}
.serviceSection .overlay {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);
	backdrop-filter: blur(20px);
	overflow: hidden;
	border-radius: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
}
.serviceSection .locked {
	background: #6d6d6d;
	padding: 5px;
	border-radius: 50px;
	color: white;
	font-weight: 500;
	font-size: 20px;

	z-index: 999;



}

.serviceHeader {
	display: flex;
	width: 100%;
	background: #f5f5f5;
	align-items: center;
}

.serviceInputs.form-control {
	border-radius: 100px !important;
	width: 25% !important;
	padding: 5px 10px;
	height: auto !important;
	font-size: 14px !important;
	border-color: transparent !important;
	margin: 0 10px;
}

.serviceBadge {
	font-size: 10px;
	color: #ffffff;
	padding: 5px 10px;
	border-radius: 100px;
	background: #6d6d6d;
	display: inline-block;
	margin: 0 5px;
}

.serviceInputs.form-control::placeholder {
	color: #808080;
}

.ServiceTabletitle {
	font-weight: 500;
	font-size: 17px;
	padding: 12px 20px;
}

.serviceSection .tableHeader {
	text-align: center;
	display: flex;
	justify-content: center;
	width: 60px;
}

.serviceSection .photo {
	width: 60px;
}

.serviceSection .businessName {
	width: 200px;
}

.serviceSection .recognition {
	width: 200px;
}

.serviceSection .postcode {
	width: 80px;
}

.serviceSection .distance {
	width: 100px;
}

.serviceSection .selections {
	width: 200px;
}

.serviceSection td {
	padding: 0 !important;
}
.serviceSection .tableHeader.large {
	width: 120px;
}

.serviceSection .tableContent {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: capitalize;
	min-height: 40px;
	margin: 10px 0;
	gap: 10px;
	flex-wrap: wrap;
}

.serviceSection .tableImage {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}
.serviceSection .serviceSelectionButton .hovered {
	opacity: 0;
	transition: all linear 0.2s;
}
.serviceSection .serviceSelectionButton {
	width: 85%;
	border-radius: 100px;
	font-weight: 700;
	font-style: italic;
	background: #6d6d6d;
	color: #ffffff;
	transition: all linear 0.2s;
	border-color: transparent;
	padding: 5px 0;
}
.serviceSection .serviceSelectionButton:hover .hovered {
	opacity: 1;
}
.serviceSection .serviceSelectionButton:hover {
	background: #fac804;
}

.serviceSection .servicePointNotAvail {
	margin-left: 60px;
	width: 500%;
}
