@import "src/common/styles/variables";

.feedback-card-wrap {
	align-items: center;
	text-align: center;
	transition: box-shadow 200ms;
	box-shadow: none;
	border: none !important;
	color: $primaryGray;
	padding: 3rem;
	margin: 20px 0;
	min-height: 514.875px;

	&:hover {
		box-shadow: 0 0 20px 0 rgba(70, 70, 70, 0.3);
	}
}
