@import "src/common/styles/variables";

.logo {
	img {
		width: 80%;
		margin-top: 12px;
	}
}

.hide-sm {
	@media (max-width: 767px) {
		display: none;
	}
}
.hide-md {
	@media (min-width: 768px) {
		display: none;
	}
}
.header {
	background: $bgLightGray;
	box-shadow: 0 5px 20px 0 rgba(70, 70, 70, 0.3);
	position: sticky;
	top: 0;
	z-index: 10;
	.container {
		padding: 8px 15px;

		.row {
			@media (max-width: 767px) {
				margin-right: 0;
				margin-left: 0;
			}

			.mid-col {
				@media (max-width: 767px) {
					padding: 0;
				}
			}
		}

		.phonenumber {
			float: right;

			span {
				color: $primaryBlue;
				font-size: 25px;
				font-weight: 700;
				font-family: sans-serif, Montserrat;
			}
			.phoneanchor {
				span:hover {
					text-decoration: underline;
				}
			}
			.phone-icon {
				font-size: 25px;
				color: $primaryBlue;
			}
			.bars-icon {
				margin-top: 20px;
				font-size: 25px;
				color: $primaryBlue;
				margin-left: 15px;
			}
			.time {
				font-size: 16px;
				color: $secondaryGray;
				font-family: sans-serif, Montserrat;
			}
		}
		.reviewstate {
			font-size: 45px;
			color: $primaryGray;
			font-weight: 700;
			line-height: normal;
			margin-top: 2px;
			font-family: sans-serif;
			@media (max-width: 767px) {
				font-size: 14px;
			}
		}
		.review {
			float: right;
			@media (max-width: 767px) {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 0;
			}
		}

		.startrating {
			margin-top: -24px;
		}
	}
	.departmentChanger {
		position: fixed;
		right: 0;
		bottom: 0;
	}
}
