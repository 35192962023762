@import "src/common/styles/variables";

.service-code-popup {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	margin: 0 auto !important;

	&.alert-box-popupss {
		.modal-body {
			border-radius: 15px;
			background-color: $lightRed;
		}
		.service-code-heading,
		.service-code-about {
			color: $darkRed;
		}
	}

	p {
		padding: 0;
		margin: 0;
	}
	.modal-header {
		border-bottom: none !important;
	}
	.modal-content {
		.modal-header {
			padding: 0 !important;
			position: relative;
			button {
				position: inherit;
				top: 7px;
				right: 10px;
				outline: none;
				z-index: 99;
			}
		}
	}
	.modal-body {
		padding: 1.5rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		font-style: italic;
	}
	.service-code-heading {
		color: $primaryBlue;
		font-weight: 900;
		font-size: 22px;
		margin-top: 5px;
		margin-bottom: 0;
	}
	.service-code-quantity {
		text-align: center;
		padding: 0 30px 0 30px;
		background: $primaryBlue;
		color: $white;
		font-weight: 900;
		border-radius: 5px;
		margin: 10px 0 10px 0;
		font-style: normal;
		font-size: 24px;
	}
	.service-code-about {
		color: $secondaryGray;
		font-weight: bold;
		font-size: 14px;
		text-align: center;
		margin: 5px 40px;
	}
	.code-wrapper {
		margin-top: 15px;
		display: flex;
		justify-content: center;
	}
	.service-code-label {
		color: $primaryBlue;
		font-size: 22px;
		font-weight: 900;
		@media screen and (max-width: 375px) {
			font-size: 19px;
		}
		.service-code a {
			margin-right: 5px;
			color: $primaryBlue;
		}
	}
	.retry-btn {
		margin-top: 25px;
		color: $bgLightGray;
		width: 40%;
		outline: 0 !important;
		padding: 9px 0;
		border: 0;
		background-color: $btnSecondaryYellow;
		border-radius: 26px;
		border-bottom: 3px solid $btnSecondaryYellow;
		border-top: 0 solid $darkyello;

		&:hover {
			border-bottom: 3px solid $btnSecondaryYellow;
			background-color: $btnSecondaryYellow;
		}
	}
}
