@import "src/common/styles/variables";

.stepper {
	border: none;
	.stepwizard-row {
		display: flex;
		justify-content: space-around;
	}
	.stepwizard {
		margin: 20px;
		display: table;
		width: 100%;
		position: relative;
	}
	.stepwizard-step button[disabled] {
		opacity: 1 !important;
		filter: alpha(opacity=100) !important;
	}
	.stepwizard-row:before {
		top: 14px;
		bottom: 0;
		position: absolute;
		content: " ";
		width: 100%;
		height: 1.5px;
		background-color: #ccc;
	}
	.stepwizard-step {
		display: table-cell;
		text-align: center;
		position: relative;
		max-width: 250px;
		width: 100%;
	}
	.stepper-title {
		color: $textDarkGray;
		font-size: 14px;
		font-weight: bold;
		margin: 5px 10px 10px 10px;

		svg {
			cursor: pointer;
		}
	}
	.btn-circle {
		width: 30px;
		height: 30px;
		text-align: center;
		padding: 6px 0;
		font-size: 12px;
		line-height: 1.428571429;
		border-radius: 15px;
	}
	.btn-primary {
		color: $bgLightGray;
		background-color: $primaryBlue;
		border-color: $darkerBlue;
		&:focus {
			color: $bgLightGray;
			background-color: $primaryBlue;
			border-color: $darkerBlue;
			box-shadow: none;
		}
		&:hover {
			background: $bgLightGray;
			color: $primaryDarkerBlue;
			border: 1px solid $primaryDarkerBlue;
			box-shadow: 1px 2px $primaryDarkerBlue;
		}
	}
	.btn-default {
		color: $bgLightGray;
		background-color: $borderGray;
		border-color: $textDarkGray;
	}
}

.selected-services-wrap {
	margin-top: 15px;
}

.total-sum {
	border-top: 1px dotted;
	width: 60%;
}

.location-steps-wrap {
	.step {
		position: relative;
		min-height: 1em;
		color: gray;
	}
	.step + .step {
		margin-top: 15px;
		margin-bottom: 12px;
	}
	.step > div:first-child {
		position: static;
		height: 0;
	}
	.step > div:not(:first-child) {
		margin-left: 1.5em;
		padding-left: 1em;
	}

	/* Circle */
	.circle {
		background: gray;
		position: relative;
		width: 1.5em;
		height: 1.5em;
		line-height: 1.5em;
		border-radius: 100%;
		color: #fff;
		text-align: center;
		box-shadow: 0 0 0 3px #fff;
	}

	/* Vertical Line */
	& .step:first-child .circle:after {
		content: " ";
		position: absolute;
		display: block;
		top: 1px;
		right: 50%;
		bottom: 1px;
		left: 50%;
		height: 100%;
		width: 1px;
		transform: scale(1, 2);
		transform-origin: 50% -100%;
		background-color: rgba(0, 0, 0, 0.25);
		// z-index: -1;
	}

	.title {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}
